<template>
    <div class="scan full-height">
        <ScannerCamera />
        <div class="target-camera">
            <v-img max-width="300" max-height="300" src="@/assets/img/cam-overlay.png" v-if="isNull(basketComplexId)" />
            <v-img max-width="600" max-height="600" src="@/assets/img/cam-overlay-barcode.png" contain v-else />
        </div>

        <div class="products-to-scan" v-if="isNotNull(basket) && productsToScan.length > 0">
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="pa-4 products-to-scan-button"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <span>{{productsToScan.length}} {{$tc('check.productsLeft', productsToScan.length)}}</span>
                    </v-btn>
                </template>

                <v-card elevation="7" width="350" class="order-details pa-5">
                    <div class="product mt-2" v-for="basketProduct in productsToScan" :key="basketProduct.product.id">
                        <div class="product-infos">
                            <v-avatar size="70">
                                <img :src="getImageUrl(basketProduct.product)">
                            </v-avatar>
                            <div class="product-details">
                                <span class="product-name" v-if="$i18n.locale === 'fr'">{{basketProduct.product.name.fr}}</span>
                                <span class="product-name" v-else>{{basketProduct.product.name.en}}</span>
                                <div class="product-price mt-1">
                                    <span class="product-price">{{formatPrice(basketProduct.product.price)}}€</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>

            </v-menu>
        </div>

        <span class="no-order products-to-scan-button" v-else-if="isNull(basket)">{{$t('check.noOrder')}}</span>
        <span class="no-order products-to-scan-button" v-else>{{$t('check.allProductsScanned')}}</span>

        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    elevation="0"
                    class="choose-camera-button"
                    min-width="250"
                    v-bind="attrs"
                    v-on="on"
                >
                    <span class="choose-camera" v-if="isNotNull(cameraToUse)">{{cameraToUse.name}}</span>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="camera in cameras" :key="camera.device.id" @click="cameraToUse = camera">
                    <v-list-item-title>{{camera.name}}</v-list-item-title>

                    <v-list-item-icon>
                        <v-icon x-small color="primary" v-if="cameraToUse && cameraToUse.device.deviceId == camera.device.deviceId">mdi-checkbox-blank-circle</v-icon>
                        <v-icon x-small color="primary" v-else>mdi-checkbox-blank-circle-outline</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>


        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>
import ScannerCamera from "@/components/IEC/ScannerCamera.vue";
import {config} from '@/assets/js/Utils.js';
var Basket = require("@/assets/js/Basket");
var Barcode = require("@/assets/js/Barcode");
var Mqtt = require("@/assets/js/MqttService");
export default {
    name: 'Scan',
    components: {ScannerCamera},
    data() {
        return {
            basketComplexId: this.$route.query.id,
            basket: null,
            scannedProducts: [],
            cameras: [],
            cameraToUse: null
        }
    },
    created() {
        this.updatePageInfo(this.$route.meta)
        if(this.isNotNull(this.basketComplexId)){
            this.getBasket(this.basketComplexId)
        }
        this.getCameras()
    },
    mounted() {
        //Barcode.start(this.cameras[0].device, this.onDecode);
    },
    computed:{
        productsToScan(){
            let products = []
            if(this.isNotNull(this.basket) && this.isNotNull(this.basket.basketProducts)){
                products = this.basket.basketProducts.filter(basketProduct => {
                    if(this.scannedProducts.filter(product => product.id === basketProduct.product.id).length === 0){
                        return true
                    }
                    return false
                })
            }
            if(products.length === 0 && this.scannedProducts.length > 0){
                this.validOrder()
            }
            return products
        }
    },
    methods:{
        getBasket(basketComplexId){
            Basket.get(basketComplexId, (data) =>{
                if(this.isNotNull(data)){
                    this.basketComplexId = basketComplexId
                    this.basket = data
                }else{
                    this.$root.$emit('showAlert', {"content":  this.$t('check.orderNotFound'), "color": "error", "timeout": "5", "closable": false});
                }
            })
        },
        async getCameras(){
            this.cameras = await Barcode.getCameras()
        },
        onDecode(decodedString) {
            this.$refs.beep.play();
            if(this.isNull(this.basketComplexId)){
                if(decodedString.codeResult.type === "QR_CODE"){
                    this.getBasket(decodedString.codeResult.code)
                }else{
                    this.$root.$emit('showAlert', {"content": this.$t('check.badScanTypeQrCode'), "color": "error", "timeout": "5", "closable": false});
                }
            }else{
                if(decodedString.codeResult.type === "EAN_13"){
                    let basketProducts = this.basket.basketProducts.filter(basketProduct => basketProduct.product.ean === decodedString.codeResult.code)
                    if(this.isNotNull(basketProducts)){
                        this.scannedProducts.push(basketProducts[0].product)
                    }else{
                        this.$root.$emit('showAlert', {"content":  this.$t('check.productNotInBasket'), "color": "error", "timeout": "5", "closable": false});
                    }
                }else{
                    this.$root.$emit('showAlert', {"content":  this.$t('check.badScanTypeBarcode'), "color": "error", "timeout": "5", "closable": false});
                }
            }
        },
        getImageUrl(product){
            return config.WS_URL + '/catalog/product/image/' + product.image
        },
        validOrder(){
            Basket.changeSubState(this.basket.complexId, "VALIDATED", (data) => {
                if(!data.error){
                    this.basket.subState = "VALIDATED"

                    let message = {
                        basketComplexId: this.basket.complexId,
                        subState: this.basket.subState
                    }
                    Mqtt.publishMessage(Mqtt.topic.ordersRefresh, JSON.stringify(message))
                }
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        /* eslint-disable */
        Barcode.reset()
        next();
    },
    watch:{
        cameras(){
            Barcode.reset()
            this.cameraToUse = this.cameras[0]
        },
        cameraToUse(camera){
            Barcode.reset()
            Barcode.start(camera.device, this.onDecode);
        }
    }
};
</script>

<style scoped>
.scan{
    background: black;
    position: relative;
}
.target-camera{
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.target-camera img{
    width: 300px;
    height: 300px;
}
.products-to-scan, .no-order{
    position: absolute;
    top: 0;
    left: 0;
    margin: 30px;
}
.products-to-scan-button{
    padding: 8px 16px;
    background: var(--primary-color) !important;
    border-radius: 12px;
    color: white;
    font-size: 1.1rem;
    text-transform: none;
}
.order-details{
    border-radius: 10px !important;
    display: grid;
}
.product{
    display: grid;
    grid-template-columns: 1fr 0fr;
    align-items: center;
    border-radius: 40px !important;
    border: 1px solid var(--primary-color);
}
.product-name{
    font-size: 13px;
}
.product-infos{
    display: grid;
    grid-template-columns: 0fr 1fr;
}
.product-price{
    color: var(--primary-color);
}
.product-infos{
    align-items: center;
}
.choose-camera-button{
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px !important;;
    padding: 8px 16px !important;
    background: var(--primary-color) !important;
    border-radius: 12px;
    color: white !important;;
    font-size: 1.1rem;
    text-transform: none;
}
.choose-camera{
    display: flex;
    align-items: center;
}
</style>
