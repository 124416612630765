import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import i18n from '@/i18n'
const codeReader = new BrowserMultiFormatReader();

export function getCameras(){
    let listingVideoInput = []

    return codeReader.listVideoInputDevices()
        .then((videoInputDevices) => {
            let backCamera = 1
            let frontCamera = 1
            let otherCamera = 1
            videoInputDevices.forEach(videoInputDevice => {
                let name = ""
                if(/back|rear|environment/gi.test(videoInputDevice.label)){
                    name = i18n.t('camera.back') + backCamera
                    backCamera++
                }else if(/front|user/gi.test(videoInputDevice.label)){
                    name = i18n.t('camera.front') + frontCamera
                    frontCamera++
                }else if(/webcam/gi.test(videoInputDevice.label)){
                    name = i18n.t('camera.webcam') + frontCamera
                    frontCamera++
                }else{
                    name = i18n.t('camera.other') + otherCamera
                    otherCamera++
                }
                let obj = {
                    name: name,
                    device: videoInputDevice
                }
                listingVideoInput.push(obj)
            })
            return listingVideoInput
        })
}

export function start(camera, callback){
    codeReader.timeBetweenScansMillis = 2000
    codeReader.decodeFromVideoDevice(camera.deviceId, 'video', (result, err) => {
        if (result) {
            console.log(result)
            callback({
                codeResult: {
                    code: result.text,
                    type: getType(result.format)
                }
            })
        }
        if (err && !(err instanceof NotFoundException)) {
            console.error(err)
        }
    })
}

export function reset(){
    codeReader.reset()
}

function getType(code){
    switch (code){
        case 0 : return "AZTEC"
        case 1 : return "CODABAR"
        case 2 : return "CODE_39"
        case 3 : return "CODE_93"
        case 4 : return "CODE_128"
        case 5 : return "DATA_MATRIX"
        case 6 : return "EAN_8"
        case 7 : return "EAN_13"
        case 8 : return "ITF"
        case 9 : return "MAXICODE"
        case 10 : return "PDF_417"
        case 11 : return "QR_CODE"
        case 12 : return "RSS_14"
        case 13 : return "RSS_EXPANDED"
        case 14 : return "UPC_A"
        case 15 : return "UPC_E"
        case 16 : return "UPC_EAN_EXTENSION"
        default : return "UNKNOWN"
    }
}