<template>
    <div class="camera-wrapper">
        <div ref="cameraOverlay" class="camera-overlay">
            <canvas id="canvas" hidden></canvas>
        </div>
        <video v-show="loaded" id="video" ref="barcode" class="cameraCustom"></video>
    </div>
</template>

<script>
export default {
    name: 'ScannerCamera',
    components: {},
    data() {
        return {
            loaded: false
        }
    },
    mounted() {
        this.$refs.barcode.onloadeddata = this.drawOverlay

        window.addEventListener('resize', this.drawOverlay, false);
    },
    methods: {
        drawOverlay() {
            this.loaded = false
            let canvas = document.getElementById("canvas")
            let ctx = canvas.getContext("2d")
            ctx.canvas.height = this.$refs.cameraOverlay.clientHeight
            ctx.canvas.width = this.$refs.cameraOverlay.clientWidth
            let rectWidth = this.$refs.barcode.videoWidth / 2
            let rectHeight = this.$refs.barcode.videoHeight / 3
            if (this.$refs.barcode.clientWidth > this.$refs.barcode.videoWidth) {
                rectWidth = this.$refs.barcode.clientWidth / 2
                rectHeight = this.$refs.barcode.clientHeight / 3
            }
            let rectCoordinates = {
                x: (ctx.canvas.width - rectWidth) / 2,
                y: (ctx.canvas.height - rectHeight) / 2
            }

            ctx.beginPath()
            ctx.moveTo(0, 0)
            ctx.lineTo(canvas.width, 0)
            ctx.lineTo(canvas.width, rectCoordinates.y + rectHeight)
            ctx.lineTo(rectCoordinates.x + rectWidth, rectCoordinates.y + rectHeight)
            ctx.lineTo(rectCoordinates.x + rectWidth, rectCoordinates.y)
            ctx.lineTo(rectCoordinates.x, rectCoordinates.y)
            ctx.lineTo(rectCoordinates.x, rectCoordinates.y + rectHeight)
            ctx.lineTo(rectCoordinates.x + rectWidth, rectCoordinates.y + rectHeight)
            ctx.lineTo(canvas.width, rectCoordinates.y + rectHeight)
            ctx.lineTo(canvas.width, canvas.height)
            ctx.lineTo(0, canvas.height)
            ctx.closePath()
            ctx.fillStyle = "rgba(0,0,0,0.5)"
            ctx.lineWidth = 2
            ctx.fill();
            ctx.strokeStyle = "white"
            ctx.strokeRect(rectCoordinates.x, rectCoordinates.y, rectWidth, rectHeight)
            let cameraOverlay = this.$refs.cameraOverlay
            //cameraOverlay.setAttribute("style", `background-image:url("${canvas.toDataURL("data:image/svg")}")`)
            this.loaded = true
        }
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.drawOverlay, false);
    }
}
</script>

<style scoped>
.camera-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
}

.camera-wrapper video {
    height: 100%;
}

.camera-overlay {
    /*  position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;*/
}
</style>
